import { RiArrowLeftDoubleFill } from 'react-icons/ri';

import styled, { css } from 'styled-components';

import { sizes } from '~/common/constants/sizes';

interface ISidebarContainerProps {
  readonly collapsed: boolean;
}
export const SidebarContainer = styled.div<ISidebarContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  max-height: 100%;
  transition: 0.25s;
  width: ${({ collapsed }) => (collapsed ? '90px' : '280px')};
  padding: ${sizes.spacing.md};
  background-color: ${({ theme }) => theme.background_darker};

  > div:first-of-type {
    display: flex;
    flex-direction: column;
    max-height: calc(100% - 100px);
    height: calc(100% - 150px);
  }

  #logo {
    transition: 0.25s !important;
    opacity: ${({ collapsed }) => (collapsed ? 0 : 1)} !important;
  }
`;

export const CollapseArrow = styled(
  RiArrowLeftDoubleFill
)<ISidebarContainerProps>`
  transition: 0.25s;
  ${({ collapsed }) =>
    collapsed &&
    css`
      transform: rotate(180deg);
    `}
`;

export const CollapseButtonWrapper = styled.div`
  width: 40px;
  height: 30px;
  border-radius: ${sizes.border.md};
  background-color: ${({ theme }) => theme.green};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.25s;

  &:hover {
    filter: brightness(1.25);
  }
`;

export const HeaderContainer = styled.div`
  @media (max-width: 750px) {
    display: none;
  }

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: ${sizes.spacing.sm};
  padding-right: 5%;
  padding-left: 5%;
  height: 50px;
  > img {
    width: 100%;
    object-fit: contain;
  }
`;

export const MenuListContainer = styled.ul`
  width: 100%;
  height: 100%;
  overflow-y: visible;
  overflow-x: hidden;
  scrollbar-width: thin;
  display: flex;
  flex-direction: column;
  margin-top: 14px;
  gap: ${sizes.spacing.xsm};
`;

export const SidebarFooter = styled.ul`
  display: flex;
  flex-direction: column;
  height: 150px;

  > hr {
    color: ${({ theme }) => theme.text};
    opacity: 0.25;
  }
`;
