/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { useRouter } from 'next/router';

import { IAnalysisFavoriteCoin } from '~/pages-modules/analysys/interfaces/i-analysis-favorite-coin';

import { useToast } from './toast';
import { useAuth } from './auth';
import { IFCWithChildren } from '../interfaces/i-fc-with-children';
import { IConfigurationUserParams } from '../interfaces/i-configuration-user-params';
import { useModal } from '../hooks/use-modal';
import { useCookies } from '../hooks/use-cookies';
import { UserTypes } from '../enums/user-types.enum';
import { UserSubscriptionTypes } from '../enums/user-subscription-types.enum';
import { ToastTypesEnum } from '../enums/toast-types-enum';
import { sizes } from '../constants/sizes';
import { cookies } from '../constants/cookies';
import { UseTermsModal } from '../components/UseTermsModal';
import { PrivacyPoliciesModal } from '../components/PrivacyPoliciesModal';
import { EndedSubscriptionPromptModal } from '../components/EndedSubscriptionPromptModal';
import { ConfirmationModal } from '../components/ConfirmationModal';
import { Button } from '../components/Button';
import { api } from '../api';
import { TSubscriptionData } from '~/layouts/private/components/MyAccountModal';

interface IConfigurationsContextProps {
  readonly subscription: UserSubscriptionTypes;
  readonly favoriteCoins: IAnalysisFavoriteCoin[];
  readonly hiddenCoins: number[];
  readonly enabledFreeTrial: boolean;
  readonly notifications: Record<string, boolean>;
  readonly subscriptionData: TSubscriptionData;
  readonly handleHideCoin: (coinId: number) => void;
  readonly handleUnhideCoin: (coinId: number) => void;
}

const ConfigurationsContext = createContext<IConfigurationsContextProps>(
  {} as IConfigurationsContextProps
);

const ConfigurationsProvider: IFCWithChildren = ({ children }) => {
  const router = useRouter();
  const { getItem, setItem } = useCookies();
  const { updateUserType } = useAuth();
  const { openModal, closeModal } = useModal();
  const { fireToast } = useToast();
  const [hiddenCoins, setHiddenCoins] = useState<number[]>([]);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [favoriteCoins, setFavoriteCoins] = useState<IAnalysisFavoriteCoin[]>(
    []
  );
  const [subscriptionData, setSubscriptionData] = useState<TSubscriptionData>(
    {} as TSubscriptionData
  );
  
  const [enabledFreeTrial, setEnabledFreeTrial] = useState<boolean>(false);
  const [subscription, setSubscription] = useState<UserSubscriptionTypes>(
    getItem(cookies.USER_SUBSCRIPTION) as UserSubscriptionTypes
  );

  const [notifications, setNotifications] = useState<Record<string, boolean>>(
    {}
  );

  const [userParams, setUserParams] = useState<IConfigurationUserParams>(
    {} as IConfigurationUserParams
  );

  const handleUnhideCoin = useCallback((coinId: number) => {
    setHiddenCoins((prev) => {
      const toSet = prev.filter((where) => Number(where) !== Number(coinId));
      api.delete(`/HiddenCoin/${coinId}`);
      return toSet;
    });
  }, []);

  const handleHideCoin = useCallback(
    (coinId: number) => {
      openModal(ConfirmationModal, {
        modalProps: {
          title: 'Atenção',
        },
        props: {
          text: 'Confirma a ação de ocultar a moeda?',
          onCancel: closeModal,
          onConfirm: () => {
            closeModal();
            setHiddenCoins((prev) => {
              const toSet = [...(prev || []), coinId];
              api.post(`/HiddenCoin/${coinId}`);
              return toSet;
            });
          },
        },
      });
    },
    [closeModal, openModal]
  );

  const handleSubscriptionEndedPromptModal = useCallback(() => {
    openModal(EndedSubscriptionPromptModal, {
      modalProps: {
        title: 'Atenção',
      },
      props: {
        onDontShowMore: async () => {
          try {
            await api.patch('/UserParams', {
              showEndedSubscriptionPrompt: false,
            });

            setUserParams((prev) => ({
              ...prev,
              showEndedSubscriptionPrompt: false,
            }));

            closeModal();
          } catch {
            fireToast({
              type: ToastTypesEnum.error,
              message: 'Houve um erro inesperado ao salvar essa ação',
            });
          }
        },
      },
    });
  }, [closeModal, fireToast, openModal]);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.post('/MeusDados/Carregar');

        const {
          value: {
            userType: _subscription,
            elegivelParaTesteGratis,
            favoritos,
            notificacoes,
            hiddenCoins: currentHiddenCoins,
            userParams: userParamsResponse,
            plano
          },
        } = data;

        const toCheck = (_subscription as string)
          .toLowerCase()
          .trim()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '');

        const subscriptionsTypes: Record<string, UserSubscriptionTypes> = {
          gratuita: UserSubscriptionTypes.free,
          standard: UserSubscriptionTypes.standard,
          premium: UserSubscriptionTypes.premium,
          vip: UserSubscriptionTypes.vip,
          admin: UserSubscriptionTypes.admin,
          platinum: UserSubscriptionTypes.platinum,
        };

        const toSet = subscriptionsTypes[toCheck];
        setHiddenCoins(
          currentHiddenCoins?.length
            ? currentHiddenCoins.map((item) => Number(item.id))
            : []
        );
        setItem(cookies.USER_SUBSCRIPTION, toSet);
        setNotifications(notificacoes);
        setUserParams(userParamsResponse);
        setSubscription(toSet);
        setEnabledFreeTrial(elegivelParaTesteGratis || false);
        updateUserType(_subscription as UserTypes);
        setSubscriptionData(plano);

        if ((favoritos || []).length) {
          setFavoriteCoins(
            (favoritos as any[]).map((fav) => ({
              id: fav.id,
              acronym: fav.sigla,
              image: fav.imagemUrl64,
              name: fav.nome,
            }))
          );
        }
      } catch {
      } finally {
        setLoaded(true);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);

  useEffect(() => {
    if (userParams.showEndedSubscriptionPrompt) {
      handleSubscriptionEndedPromptModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userParams]);

  useEffect(() => {
    if (!!loaded && !userParams.acceptedTerms) {
      const fc: FC = () => {
        const handleConfirm = async () => {
          await api
            .patch('/UserParams', {
              acceptedTerms: true,
            })
            .then(() => {
              setUserParams((prev) => ({
                ...prev,
                acceptedTerms: true,
              }));
            })
            .catch(() => {
              fireToast({
                type: ToastTypesEnum.warning,
                message: 'Houve um erro ao confirmar a operação',
              });
            })
            .finally(closeModal);
        };

        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: sizes.spacing.xlg,
            }}
          >
            <UseTermsModal withoutContact />
            <PrivacyPoliciesModal />

            <Button text="Aceito" textColor="white" onClick={handleConfirm} />
          </div>
        );
      };

      openModal(fc, {
        modalProps: {
          showHeader: false,
          closeOnClickOutside: false,
          closeOnEsc: false,
          closeOnX: false,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded, userParams]);

  return (
    <ConfigurationsContext.Provider
      value={{
        subscription,
        enabledFreeTrial,
        favoriteCoins,
        notifications,
        hiddenCoins,
        subscriptionData,
        handleHideCoin,
        handleUnhideCoin,
      }}
    >
      {children}
    </ConfigurationsContext.Provider>
  );
};

const useConfigurations = () => useContext(ConfigurationsContext);
export { useConfigurations, ConfigurationsProvider };
