import { FC, useEffect, useMemo, useState } from 'react';

import { useRouter } from 'next/router';

import { useSidebar } from '~/common/providers/sidebar';
import { ISidebarData } from '~/common/interfaces/i-sidebar-data';

import { MenuContainer } from './styles';
import { BaseNavOption } from '../BaseNavOption';

export interface INavMenuProps {
  readonly menu: Omit<ISidebarData, 'route'>;
  readonly collapsed: boolean;
}
export const NavMenu: FC<INavMenuProps> = ({ menu, collapsed }) => {
  const { collapseMenuState } = useSidebar();
  const router = useRouter();
  const [subMenuOpen, setSubMenuOpen] = useState<string>();
  const [fixSubMenuScreen, setFixSubMenuScreen] = useState<string>();

  const subMenuHeightKeySet = useMemo(() => {
    const childrenWithSubMenu =
      menu.children.filter((ch) => ch.subchildren?.length > 0) || [];

    if (!childrenWithSubMenu.length) {
      return null;
    }

    const keySet = {};
    for (const childWithSub of childrenWithSubMenu) {
      const { subchildren, tag } = childWithSub;
      Object.assign(keySet, {
        [tag]: subchildren.length * 50,
      });
    }

    return keySet;
  }, [menu]);

  const menuHeight = useMemo(() => {
    let baseHeight = 10 + menu.children.length * 50;
    if (subMenuOpen && subMenuHeightKeySet) {
      const extraHeight = subMenuHeightKeySet[subMenuOpen] as number;
      baseHeight += extraHeight;
    }

    return baseHeight;
  }, [menu, subMenuHeightKeySet, subMenuOpen]);

  useEffect(() => {
    if (router) {
      setFixSubMenuScreen(null);
      const { asPath } = router;
      const { tag } = menu;
      const childrenWithSubMenu =
        menu.children.filter((ch) => ch.subchildren?.length > 0) || [];

      const subMenuTags =
        childrenWithSubMenu.map((ch) => `/${tag}/${ch.tag}`) || [];

      if (subMenuTags.length) {
        const included = subMenuTags.find((smt) => asPath.includes(smt));

        if (included) {
          const split = included.split('/');

          const populatedMenu = childrenWithSubMenu.find(
            (mn) => mn.tag === split[split.length - 1]
          );

          if (populatedMenu) {
            setSubMenuOpen(populatedMenu.tag);
            setFixSubMenuScreen(populatedMenu.tag);
          }
        }
      }
    }
  }, [menu, router]);

  useEffect(() => {
    return () => {
      setFixSubMenuScreen(null);
      setSubMenuOpen(null);
    };
  }, []);

  return (
    <>
      <BaseNavOption nav={menu} collapsed={collapsed} />
      <MenuContainer height={menuHeight} open={!!collapseMenuState[menu.tag]}>
        {menu.children.map((menuItem) => (
          <li key={menuItem.tag}>
            <BaseNavOption
              setSubMenuOpen={setSubMenuOpen}
              collapsed={collapsed}
              isSubMenuOpen={subMenuOpen === menuItem.tag}
              isOpenOnScreen={fixSubMenuScreen === menuItem.tag}
              key={menuItem.tag}
              nav={{
                ...menuItem,
                parent: menu.tag,
              }}
            />
          </li>
        ))}
      </MenuContainer>
    </>
  );
};
