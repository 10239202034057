import { FC, useCallback, useMemo, useState } from 'react';

import { SlLogout } from 'react-icons/sl';
import { RiArrowLeftDoubleFill } from 'react-icons/ri';
import { MdManageAccounts } from 'react-icons/md';
import { FiSettings } from 'react-icons/fi';
import { FaWhatsapp } from 'react-icons/fa';

import { useRouter } from 'next/router';
import Image from 'next/image';

import { Fade, Tooltip } from '@mui/material';

import { useConfigurations } from '~/common/providers/subscription';
import { useAuth } from '~/common/providers/auth';
import { useModal } from '~/common/hooks/use-modal';
import { UserSubscriptionTypes } from '~/common/enums/user-subscription-types.enum';
import { sidebar } from '~/common/constants/sidebar';
import { routes } from '~/common/constants/routes';

import {
  CollapseArrow,
  CollapseButtonWrapper,
  HeaderContainer,
  MenuListContainer,
  SidebarContainer,
  SidebarFooter,
} from './styles';
import { NavMenu } from '../NavMenu';
import { MyAccountModal } from '../MyAccountModal';
import { BaseNavOption } from '../BaseNavOption';
import cclogo from '../../../../../public/images/logo-home.png';

export const Sidebar: FC = () => {
  const [desktopCollaped, setDesktopCollapsed] = useState<boolean>(false);
  const { openModal, closeModal } = useModal();
  const { subscription } = useConfigurations();
  const router = useRouter();
  const {
    logout,
    user: { userType },
  } = useAuth();

  const sidebarArr = useMemo(() => {
    if (subscription !== UserSubscriptionTypes.free) {
      return sidebar;
    }

    return [
      ...sidebar,
      {
        label: 'Assinatura',
        tag: 'subscription',
        route: routes.subscription,
        icon: MdManageAccounts,
      },
    ];
  }, [subscription]);

  const handleAccountInfoModal = useCallback(() => {
    openModal(MyAccountModal, {
      modalProps: {
        showHeader: false,
        contentStyle: {
          minHeight: '200px',
        },
      },
      props: {
        subscription,
        userType,
        close: closeModal,
        onSubscriptionRedirect: () => {
          closeModal();
          router.push(routes.subscription);
        },
      },
    });
  }, [openModal, subscription, userType, closeModal, router]);

  const switchDesktopCollapseState = useCallback(() => {
    setDesktopCollapsed((prev) => !prev);
  }, []);

  return (
    <SidebarContainer collapsed={desktopCollaped}>
      <div>
        <HeaderContainer>
          {!desktopCollaped && (
            <Image id="logo" src={cclogo} alt="logo" priority quality={100} />
          )}

          <Tooltip
            arrow
            title={desktopCollaped ? 'expandir menu' : 'esconder menu'}
          >
            <CollapseButtonWrapper onClick={switchDesktopCollapseState}>
              <CollapseArrow collapsed={desktopCollaped} size={25} />
            </CollapseButtonWrapper>
          </Tooltip>
        </HeaderContainer>

        <MenuListContainer>
          {sidebarArr.map((nav) => (
            <li key={nav.tag}>
              {nav.children?.length > 0 ? (
                <NavMenu menu={nav} collapsed={desktopCollaped} />
              ) : (
                <BaseNavOption
                  nav={nav}
                  highlight={nav.tag === 'subscription'}
                  collapsed={desktopCollaped}
                />
              )}
            </li>
          ))}
        </MenuListContainer>
      </div>

      <SidebarFooter>
        <li>
          <hr />
        </li>

        <li>
          <BaseNavOption
            collapsed={desktopCollaped}
            nav={{
              tag: 'p2p',
              label: 'P2P',
              iconColor: 'green',
              icon: FaWhatsapp,
              blankRoute: true,
              route:
                'https://wa.me/555192720014?text=Ol%C3%A1%2C%20sou%20assinante%20do%20CryptoControl%2C%20estou%20entrando%20em%20contato%20para%20poder%20comprar%20criptos%20via%20P2P',
            }}
          />
        </li>
        <li>
          <BaseNavOption
            collapsed={desktopCollaped}
            nav={{
              tag: 'settings',
              label: 'Configurações',
              icon: FiSettings,
              onClick: handleAccountInfoModal,
            }}
          />
        </li>

        <li>
          <BaseNavOption
            collapsed={desktopCollaped}
            nav={{
              tag: 'logout',
              label: 'Sair',
              icon: SlLogout,
              onClick: logout,
            }}
          />
        </li>
      </SidebarFooter>
    </SidebarContainer>
  );
};
