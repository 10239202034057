import styled from 'styled-components';

import { themes } from '~/common/styles/themes';
import { sizes } from '~/common/constants/sizes';

import { IUseTermsModalProps } from '.';

export const UseTermsModalContainer = styled.div<IUseTermsModalProps>`
  display: flex;
  flex-direction: column;
  gap: ${sizes.spacing.xlg};
  > div {
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacing.sm};

    h1 {
      font-weight: bold;
    }

    h2 {
      font-size: 16px;
      font-weight: bold;
      color: ${({ theme, reverse }) =>
        reverse ? theme.text_reverse : theme.text};
    }

    h1,
    span,
    b {
      font-size: 12px;
      color: ${({ theme, reverse }) =>
        reverse ? theme.text_reverse : theme.text};
    }

    span {
      opacity: 0.75;
    }

    b {
      color: ${themes.dark.green};
      text-decoration: underline;
    }

    ul {
      display: flex;
      flex-direction: column;
      gap: ${sizes.spacing.xsm};
      color: ${({ theme, reverse }) =>
        reverse ? theme.text_reverse : theme.text};
      font-size: 12px;
    }
  }
`;
