import { FC, useCallback, useState } from 'react';

import { Container } from './styles';
import { Text } from '../Text';
import { Button } from '../Button';

interface IEndedSubscriptionPromptModalProps {
  readonly onDontShowMore: () => Promise<void>;
}
export const EndedSubscriptionPromptModal: FC<
  IEndedSubscriptionPromptModalProps
> = ({ onDontShowMore }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleDontShowMore = useCallback(async () => {
    setLoading(true);
    await onDontShowMore().finally(() => {
      setLoading(false);
    });
  }, [onDontShowMore]);

  return (
    <Container>
      <Text size="md">
        Olá, vimos que houve uma falha na renovação da sua assinatura. Neste
        momento, sua conta foi migrada para o plano gratuito. Este plano possui
        algumas limitações: você poderá visualizar apenas 5 moedas no portfólio
        e terá acesso restrito a alguns setores. Se você tiver mais de 5 moedas
        cadastradas, não se preocupe, todas as suas informações ainda estão
        conosco. Para visualizá-las por completo, basta optar por um de nossos
        planos acima do gratuito. Em caso de dúvidas, entre em contato com o
        suporte em: <b>contact@cryptocontrol.pro</b>
      </Text>

      <Button
        text="Estou ciente"
        textColor="green"
        color="background2"
        borderColor="green"
        loading={loading}
        onClick={handleDontShowMore}
      />
    </Container>
  );
};
